import { HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer, withRouterConfig } from '@angular/router';
import { MissingTranslationHandler, provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { provideMarkdown } from 'ngx-markdown';
import { provideSfxCommons } from 'sfx-commons';
import { AppInitializerService } from './core/services/app-initializer.service';
import { SfxErrorHandler } from './core/sfx-error-handler/sfx-error-handler';
import { CleanUrlSerializer } from './core/utils/clean-url-serializer';
import { provideSfxDiInterceptors } from './core/utils/http-interceptors.utils';
import { MadMissingTranslationHandler } from './mad-translate/mad-missing-translations-handler';
import { HttpTranslateLoaderFactory } from './mad-translate/mad-translate-loader';
import { AuthenticationService } from './projects/services/authentication.service';

const configureAsyncRoutesAndAuth = (appInitSvc: AppInitializerService, authSvc: AuthenticationService) => {
    return async () => {
        try {
            await appInitSvc.init();
            await authSvc.init();
        } catch (ex) {
            console.error(ex);
        }
    };
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withJsonpSupport(), withInterceptorsFromDi()),
        provideSfxDiInterceptors(),
        provideOAuthClient(),
        provideRouter([], withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MadMissingTranslationHandler
            },
            useDefaultLang: false
        }),
        AppInitializerService,
        provideAppInitializer(() =>
            configureAsyncRoutesAndAuth(inject(AppInitializerService), inject(AuthenticationService))()
        ),
        { provide: ErrorHandler, useClass: SfxErrorHandler },
        { provide: UrlSerializer, useClass: CleanUrlSerializer },
        provideNzConfig({ notification: { nzPlacement: 'bottomRight' } }),
        provideSfxCommons(),
        provideMarkdown(),
        provideAnimations()
    ]
};
